import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
import { createPinia } from 'pinia'
import App from './App.vue';
import plugins from './plugins/plugins.js';

// Vue router
const routes = [
    {
        path: "/",
        name: "LayoutPublic",
        component: () => import('./components/layout/LayoutPublic.vue'),
        children: [
            {
                path: '/',
                name: 'HomePage',
                component: () => import('./components/route/PageLogin.vue')
            },
            {
                path: '/welcome',
                name: 'WelcomePage',
                component: () => import('./components/route/PageWelcome.vue')
            },
            {
                path: '/login',
                name: 'LoginPage',
                component: () => import('./components/route/PageLogin.vue')
            },
            {
                path: '/signup',
                name: 'SignUpPage',
                component: () => import('./components/route/account/PageSignup.vue')
            },
            {
                path: '/activate',
                name: 'SignUpActivationPage',
                component: () => import('./components/route/account/PageSignupConfirm.vue')
            },
            {
                path: '/forgot',
                name: 'LoginPasswordForgot',
                component: () => import('./components/route/account/PagePasswordForgot.vue')
            },
            {
                path: '/reset',
                name: 'LoginPasswordReset',
                component: () => import('./components/route/account/PagePasswordReset.vue')
            }
        ]
    },
    {
        path: "/",
        name: "LayoutApp",
        component: () => import('./components/layout/LayoutApp.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'PageDashboard',
                component: () => import('./components/route/PageDashboard.vue'),
                meta: { title: 'Dashboard' }
            },
            {
                path: '/profile',
                name: 'UserProfilePage',
                component: () => import('./components/route/account/PageProfile.vue'),
                meta: { title: 'Your Profile' },
                children: [
                    {
                        path: 'update',
                        name: 'UserProfileUpdate',
                        component: () => import('./components/route/account/PageProfile.vue'),
                        meta: { title: 'Your Profile' }
                    }
                ]
            },
            {
                path: '/profile/cscs/create',
                name: 'UserProfileCSCSCreate',
                component: () => import('./components/route/account/PageProfileCSCSCreate.vue'),
                meta: { title: 'Your Profile' }
            },
            {
                path: '/profile/contact/create',
                name: 'UserProfileContactCreate',
                component: () => import('./components/route/account/PageProfileContactCreate.vue'),
                meta: { title: 'Your Profile' }
            },
            {
                path: '/profile/contact/:contactId/update',
                name: 'UserProfileContactUpdate',
                component: () => import('./components/route/account/PageProfileContactUpdate.vue'),
                meta: { title: 'Your Profile' }
            }
        ]
    }
];

// Pinia
const pinia = createPinia();

// TO DO: set-up 404 responses for missing routes.
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
});

createApp(App)
    .use(plugins, { router })
    .use(router)
    .use(pinia)
    .mount('#app');

/*
*    Numbers
*/
if (!Number.prototype.toCurrency) {
    Number.prototype.toCurrency = function (decimals) {
        var n = this,
            c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
            d = '.',
            t = ',',
            sign = n < 0 ? '-' : '',
            //extracting the absolute value of the integer part of the number and converting to string
            i = parseInt(n = Math.abs(n).toFixed(c)) + '',
            j = (j = i.length) > 3 ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
    };
}
if (!Number.prototype.toDateNumber) {
    Number.prototype.toDateNumber = function () {
        var sn = "0" + this.toString();
        return sn.substr(sn.length - 2, 2);
    };
}
if (!Number.prototype.toTime) {
    Number.prototype.toTime = function () {
        var sec_num = parseInt(this, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor(sec_num - (hours * 3600) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    };
}
/*
*    Dates
*/
const _monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const _dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
if (!Date.prototype.monthName) {
    Date.prototype.monthName = function (shortDate) {
        if (shortDate) {
            return _monthNames[this.getMonth()].substr(0, 3);
        }
        return _monthNames[this.getMonth()];
    };
}
if (!Date.prototype.dayName) {
    Date.prototype.dayName = function (shortDate) {
        var dn = this.getDay() - 1;
        if (shortDate) {
            return _dayNames[dn > -1 ? dn : 6].substr(0, 3);
        }
        return _dayNames[dn > -1 ? dn : 6];
    };
}
if (!Date.prototype.toFullDateTime) {
    Date.prototype.toFullDateTime = function () {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + " " + this.monthName() + " " + this.getFullYear() + " " + this.getHours().toDateNumber() + ":" + this.getMinutes().toDateNumber();
        }
        return "";
    };
}
if (!Date.prototype.toFullDate) {
    Date.prototype.toFullDate = function () {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + " " + this.monthName() + " " + this.getFullYear();
        }
        return "";
    };
}
if (!Date.prototype.toFullDayMonth) {
    Date.prototype.toFullDayMonth = function () {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + " " + this.monthName();
        }
        return "";
    };
}
if (!Date.prototype.toShortDayMonth) {
    Date.prototype.toShortDayMonth = function (short) {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + " " + this.monthName(short);
        }
        return "";
    };
}
if (!Date.prototype.toShortDateTime) {
    Date.prototype.toShortDateTime = function () {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + " " + this.monthName(true) + " " + this.getFullYear() + " " + this.getHours().toDateNumber() + ":" + this.getMinutes().toDateNumber();
        }
        return "";
    };
}
if (!Date.prototype.toShortDate) {
    Date.prototype.toShortDate = function () {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + " " + this.monthName(true) + " " + this.getFullYear();
        }
        return "";
    };
}
if (!Date.prototype.toShortTime) {
    Date.prototype.toShortTime = function () {
        if (!isNaN(this.valueOf())) {
            return this.getHours().toDateNumber() + ":" + this.getMinutes().toDateNumber();
        }
        return "";
    };
}
if (!Date.prototype.toFormDate) {
    Date.prototype.toFormDate = function () {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + "/" + (this.getMonth() + 1).toDateNumber() + "/" + this.getFullYear();
        }
        return "";
    };
}
if (!Date.prototype.toFormDateTime) {
    Date.prototype.toFormDateTime = function () {
        if (!isNaN(this.valueOf())) {
            return this.getDate().toDateNumber() + "/" + (this.getMonth() + 1).toDateNumber() + "/" + this.getFullYear() + " " + this.getHours().toDateNumber() + ":" + this.getMinutes().toDateNumber();
        }
        return "";
    };
}
if (!Date.prototype.addDays) {
    Date.prototype.addDays = function (dys) {
        if (!isNaN(this.valueOf())) {
            var rdt = new Date(this.valueOf());
            rdt.setDate(rdt.getDate() + parseInt(dys));
            return rdt;
        }
        return null;
    };
}
if (!Date.prototype.addMonths) {
    Date.prototype.addMonths = function (mns) {
        if (!isNaN(this.valueOf())) {
            var rdt = new Date(this.valueOf());
            rdt.setMonth(rdt.getMonth() + parseInt(mns));
            return rdt;
        }
        return null;
    };
}
if (!Date.prototype.addYears) {
    Date.prototype.addYears = function (yrs) {
        if (!isNaN(this.valueOf())) {
            var rdt = new Date(this.valueOf());
            rdt.setFullYear(rdt.getFullYear() + parseInt(yrs));
            return rdt;
        }
        return null;
    };
}
if (!Date.prototype.monthStart) {
    Date.prototype.monthStart = function () {
        if (!isNaN(this.valueOf())) {
            return new Date(this.getFullYear(), this.getMonth(), 1);
        }
        return null;
    };
}
if (!Date.prototype.monthEnd) {
    Date.prototype.monthEnd = function () {
        if (!isNaN(this.valueOf())) {
            var rdate = this.monthStart().addMonths(1); // Need month start for months with more days than the month after as add months will then add 2.
            return new Date(rdate.getFullYear(), rdate.getMonth(), 0);
        }
        return null;
    };
}
if (!Date.prototype.weekNumber) {
    Date.prototype.weekNumber = function () {
        if (!isNaN(this.valueOf())) {
            var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
            var dayNum = d.getUTCDay() || 7;
            d.setUTCDate(d.getUTCDate() + 4 - dayNum);
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        }
        return null;
    };
}