const routes = [
    /* Inductions */
    {
        parent: "LayoutApp",
        route: {
            path: '/inductions',
            name: 'InductionList',
            component: () => import('../components/route/induction/PageInductionList.vue'),
            meta: { title: 'Inductions' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/inductions/create',
            name: 'InductionCreate',
            component: () => import('../components/route/induction/PageInductionCreate.vue'),
            meta: { title: 'Inductions' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/inductions/:id',
            name: 'InductionDetails',
            component: () => import('../components/route/induction/PageInductionDetails.vue'),
            meta: { title: 'Inductions' }
        }
    },
    /*{
        parent: "LayoutApp",
        route: {
            path: '/inductions/:id/question/create',
            name: 'InductionQuestionCreate',
            component: () => import('../components/route/induction/PageInductionQuestionCreate.vue')
        }
    },*/
    {
        parent: "LayoutApp",
        route: {
            path: '/inductions/:id/question/create/:questionFor',
            name: 'InductionQuestionCreate',
            component: () => import('../components/route/induction/PageInductionQuestionCreate.vue'),
            meta: { title: 'Inductions' }
        }
    },
    /*{
        parent: "LayoutApp",
        route: {
            path: '/inductions/:inductionId/questions/:id',
            name: 'InductionQuestionDetails',
            component: () => import('../components/route/induction/PageInductionQuestionDetails.vue')
        }
    },*/
    /* Staff */
    {
        parent: "LayoutApp",
        route: {
            path: '/staff',
            name: 'StaffList',
            component: () => import('../components/route/staff/PageStaffList.vue'),
            meta: { title: 'Staff' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/staff/create',
            name: 'StaffCreate',
            component: () => import('../components/route/staff/PageStaffCreate.vue'),
            meta: { title: 'Staff' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/staff/:id',
            name: 'StaffDetails',
            component: () => import('../components/route/staff/PageStaffDetails.vue'),
            meta: { title: 'Staff' },
            children: [
                {
                    path: 'update',
                    name: 'StaffDetailsUpdate',
                    component: () => import('../components/route/staff/PageStaffDetails.vue'),
                    meta: { title: 'Staff' }
                }
            ]
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/staff/:id/cscs/create',
            name: 'StaffCSCSCreate',
            component: () => import('../components/route/staff/PageStaffCSCSCreate.vue'),
            meta: { title: 'Staff' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/staff/:id/contact/create',
            name: 'StaffContactCreate',
            component: () => import('../components/route/staff/PageStaffContactCreate.vue'),
            meta: { title: 'Staff' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/staff/:id/contact/:contactId/update',
            name: 'StaffContactUpdate',
            component: () => import('../components/route/staff/PageStaffContactUpdate.vue'),
            meta: { title: 'Staff' }
        }
    },
    /* Contracts */
    {
        parent: "LayoutApp",
        route: {
            path: '/contracts',
            name: 'ContractList',
            component: () => import('../components/route/contract/PageContractList.vue'),
            meta: { title: 'Contracts' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/contracts/create',
            name: 'ContractCreate',
            component: () => import('../components/route/contract/PageContractCreate.vue'),
            meta: { title: 'Contracts' }
        }
    },
    {
        parent: "LayoutApp",
        route: {
            path: '/contracts/:id',
            name: 'ContractDetails',
            component: () => import('../components/route/contract/PageContractDetails.vue'),
            meta: { title: 'Contracts' },
            children: [
                {
                    path: 'update',
                    name: 'ContractDetailsUpdate',
                    component: () => import('../components/route/contract/PageContractDetails.vue'),
                    meta: { title: 'Contracts' }
                }
            ]
        }
    },
    /* Staff inductions */
    {
        parent: "LayoutApp",
        route: {
            path: '/staff-inductions/:id',
            name: 'StaffInductionDetails',
            component: () => import('../components/route/staff-induction/PageStaffInductionDetails.vue'),
            meta: { title: 'Staff Induction' }
        }
    },
    /* Contract inductions */
    {
        parent: "LayoutApp",
        route: {
            path: '/contract-inductions/:id',
            name: 'ContractInductionDetails',
            component: () => import('../components/route/contract-induction/PageContractInductionDetails.vue'),
            meta: { title: 'Contract Induction' }
        }
    }
]

export default {
    install: (app, options) => {
        // Add routes
        routes.forEach((r) => { options.router.addRoute(r.parent, r.route) })
    }
}