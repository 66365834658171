// Auth Module - Holds user authorisation data
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { fetchData } from '@/tools.js'

export const useAuthUserStore = defineStore('authUser', () => {
    // State
    const user = ref(null);
    const userDetails = ref(null);
    const userRoles = {
        "a": "Admin",
        "m": "Site Manager",
        "o": "Operative"
    };

    // Getters
    const getters = {
        isLoggedIn: computed(() => {
            // Check for a user
            return !!user.value;
        }),
        getInitials: computed(() => {
            if (!user.value) return null;
            const allNames = user.value.name.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if (index === 0 || index === allNames.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials;
        }),
        userTitle: computed(() => {
            return userDetails.value ? `${userDetails.value.name}` : "Not Found";
        })
    };


    // Actions
    const setUser = function (userData) {
        userData != null ? localStorage.setItem('user', JSON.stringify(userData)) : localStorage.removeItem('user');
        user.value = userData;
    }
    const setProfile = function (iData) {
        // Set data
        userDetails.value = iData;
    }

    const actions = {
        async userLogin(credentials) {
            const { body } = await fetchData('/login', credentials)
                .then(function (response) {
                    return response;
                })
                .catch(function () {
                    return null;
                });

            if (body !== null) {
                // Set the data
                const { user } = body;
                setUser(user);
            }

            return body;
        },
        async userPasswordForgot(credentials) {
            const { body } = await fetchData('/passwordForgot', credentials)
                .then(function () {
                    return { body: true };
                })
                .catch(function (e) {
                    console.log(e)
                    return false;
                });

            return body;
        },
        async userPasswordReset(credentials) {
            const { body } = await fetchData('/passwordReset', credentials)
                .then(function (response) {
                    return response;
                })
                .catch(function (e) {
                    console.log(e)
                    return null;
                });

            if (body !== null) {
                // Set data
                const { user } = body;
                setUser(user);

                return true;
            }

            return false;
        },
        async userSignUp(userData) {
            const { body } = await fetchData('/signup', userData)
                .then(function (response) {
                    return response;
                })
                .catch(function () {
                    return false;
                });

            return body;
        },
        async userSignUpActivateFind(activationKey) {
            const { body } = await fetchData('/signupactivatefind', activationKey)
                .then(function (response) {
                    return response;
                })
                .catch(function (e) {
                    console.log(e)
                    return null;
                });

            return body;
        },
        async userSignUpActivate(userData) {
            const resp = await fetchData('/signupactivate', userData)
                .then(function (response) {
                    return response;
                })
                .catch(function (e) {
                    console.log(e)
                    return null;
                });

            if (resp.body !== null) {
                const { user } = resp.body;
                setUser(user);

                return resp;
            }

            return false;
        },
        async userLoginRefresh() {
            const { body } = await fetchData('/loginRefresh', null, 'GET')
                .then(function (response) {
                    return response;
                })
                .catch(function () {
                    return null;
                });
            if (body !== null) {
                const { user } = body;
                setUser(user);
                return true;
            }
            else {
                await actions.userLogout()
                    .then((r) => {
                        return r
                    });
                return false;
            }
        },
        async userLogout() {
            let resp = await fetchData('/logout', null, 'GET')
                .then(function (response) {
                    setUser(null);
                    return response;
                })
                .catch(function () {
                    setUser(null);
                    return null;
                });
            return resp;
        },
        initialiseStore() {
            if (localStorage.getItem('user')) {
                user.value = JSON.parse(localStorage.getItem('user'));
            }
        },
        async getProfile() {
            const { body } = await fetchData(`/userProfile`, null, 'GET')
                .then(function (response) {
                    return response;
                })
                .catch(function () {
                    return null;
                });

            setProfile(body);

            return body;
        },
        async updateProfile(item) {
            const resp = await fetchData(`/userProfile`, item, 'POST')
                .then(function (response) {
                    return response;
                })
                .catch(function (e) {
                    console.log(e)
                    return null;
                });

            return resp;
        },
        async createCSCSCard(item) {
            const resp = await fetchData(`/userProfile/cscscard`, item, 'POST')
                .then(function (response) {
                    return response;
                })
                .catch(function (e) {
                    console.log(e)
                    return null;
                });

            return resp;
        },
        async createContact(item) {
            const resp = await fetchData(`/userProfile/contact`, item, 'POST')
                .then(function (response) {
                    return response;
                })
                .catch(function (e) {
                    console.log(e)
                    return null;
                });

            return resp;
        },
        async updateContact(contactId, item) {
            const resp = await fetchData(`/userProfile/contact/${contactId}`, item, 'POST')
                .then(function (response) {
                    return response;
                })
                .catch(function (e) {
                    console.log(e)
                    return null;
                });

            return resp;
        }
    }

    function $reset() {
        userDetails.value = null
    }

    return {
        user,
        userDetails,
        userRoles,
        ...getters,
        ...actions,
        $reset
    };
})
