<script setup>

    import { onBeforeMount, onMounted } from 'vue'
    import { useAuthUserStore } from '@/stores/authUser.js'
    import { useRouter } from 'vue-router'

    const authUserStore = useAuthUserStore()
    const router = useRouter()

    onBeforeMount(() => authUserStore.initialiseStore())

    onMounted(() => {
        // Intercept links to use vue router on internal links and add GA events to external and files (downloads and external clicks are handled by GA enhanced events so we haven't added them here. Left in for info)
        window.addEventListener('click', event => {
            // ensure we use the link, in case the click has been received by a subelement
            let { target } = event;
            while (target && target.tagName !== 'A') target = target.parentNode;
            // handle only links that do not reference external resources
            if (target && target.matches("a:not([href*='://']):not([href*='mailto:']):not([href*='tel:']):not([href*='/_resources/'])") && target.href) {
                // some sanity checks taken from vue-router:
                // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
                // don't handle with control keys
                if (metaKey || altKey || ctrlKey || shiftKey) return
                // don't handle when preventDefault called
                if (defaultPrevented) return
                // don't handle right clicks
                if (button !== undefined && button !== 0) return
                // don't handle if `target="_blank"`
                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target')
                    if (/\b_blank\b/i.test(linkTarget)) return
                }
                // don't handle same page links/anchors
                const url = new URL(target.href);
                if (window.location.pathname !== url.pathname && event.preventDefault) {
                    event.preventDefault();
                    router.push(url.pathname + url.search + url.hash);
                }
            }
        })
    })

</script>

<template>
    <router-view />
</template>

<style>

    /*#region Variables
    --------------------------  */

    :root {
        /*Colours*/
        --white: #fff;
        --white-90: rgba(255, 255, 255, 0.9);
        --white-80: rgba(255, 255, 255, 0.8);
        --light-white: #f5f5f5;
        --black: #53514f;
        --grey: #52514e;
        --grey-80: rgba(82, 81, 78, 0.8);
        --grey-60: rgba(82, 81, 78, 0.6);
        --grey-40: rgba(82, 81, 78, 0.4);
        --grey-20: rgba(82, 81, 78, 0.2);
        --light-grey: #f0ede8;
        --light-grey-80: rgba(240, 237, 232, 0.8);
        --light-grey-60: rgba(240, 237, 232, 0.6);
        --light-grey-40: rgba(240, 237, 232, 0.4);
        --light-grey-20: rgba(240, 237, 232, 0.2);
        --light-grey-10: rgba(240, 237, 232, 0.1);
        --green: #205228;
        --green-80: rgba(32, 82, 40, 0.8);
        --green-60: rgba(32, 82, 40, 0.6);
        --green-40: rgba(32, 82, 40, 0.4);
        --green-20: rgba(32, 82, 40, 0.2);
        --light-green: #9ac13b;
        --light-green-80: rgba(154, 193, 59, 0.8);
        --light-green-60: rgba(154, 193, 59, 0.6);
        --light-green-40: rgba(154, 193, 59, 0.4);
        --light-green-20: rgba(154, 193, 59, 0.2);
        --yellow: #d9d800;
        --yellow-20: rgba(217, 216, 0, 0.2);
        --yellow-40: rgba(217, 216, 0, 0.4);
        --highlight-80: rgba(237, 148, 131, 0.8);
        --warning: #b72d2d;
        --highlight: #ed9483;
        --highlight-80: rgba(237, 148, 131, 0.8);
        --highlight-60: rgba(237, 148, 131, 0.6);
        --highlight-20: rgba(237, 148, 131, 0.2);
        /*SVG Filters*/
        --svg-color-logo: url(#svg-logo);
        --svg-color-icon: url(#svg-icon);
        --svg-color-icon-light: url(#svg-icon-light);
        --svg-color-icon-highlight: url(#svg-icon-highlight);
        --svg-color-icon-warning: url(#svg-icon-warning);
        --svg-icon-red: url(#svg-icon-green);
        --svg-icon-green: url(#svg-icon-green);
        --svg-icon-yellow: url(#svg-icon-yellow);
        /*Fonts */
        --font-heading: davis, sans-serif;
        --font-text: effra-cc, sans-serif;
        /* Fluid Font Sizes - Calculate the sizes based on the viewport width.
        This will change the fonts based on the viewport size. The min and max set the font at those sizes.
        They do not stop the font size changing above and below those sizes so we still need to put those in the media queries
        */
        --viewport-min: 320px;
        --viewport-max: 1600px;
        --viewport-min-v: 320;
        --viewport-max-v: 1600;
        --font-h1-min-v: 28;
        --font-h1-max-v: 32;
        --font-h1-min: 28px;
        --font-h1-max: 32px;
        --font-h1: calc(var(--font-h1-min) + (var(--font-h1-max-v) - var(--font-h1-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-h2-min-v: 25;
        --font-h2-max-v: 28;
        --font-h2-min: 25px;
        --font-h2-max: 28px;
        --font-h2: calc(var(--font-h2-min) + (var(--font-h2-max-v) - var(--font-h2-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-h3-min-v: 21;
        --font-h3-max-v: 24;
        --font-h3-min: 21px;
        --font-h3-max: 24px;
        --font-h3: calc(var(--font-h3-min) + (var(--font-h3-max-v) - var(--font-h3-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-heading-small-min-v: 18;
        --font-heading-small-max-v: 22;
        --font-heading-small-min: 18px;
        --font-heading-small-max: 22px;
        --font-heading-small: calc(var(--font-heading-small-min) + (var(--font-heading-small-max-v) - var(--font-heading-small-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-p-min-v: 14;
        --font-p-max-v: 18;
        --font-p-min: 14px;
        --font-p-max: 18px;
        --font-p: calc(var(--font-p-min) + (var(--font-p-max-v) - var(--font-p-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-p-small-min-v: 11;
        --font-p-small-max-v: 14;
        --font-p-small-min: 11px;
        --font-p-small-max: 14px;
        --font-p-small: calc(var(--font-p-small-min) + (var(--font-p-small-max-v) - var(--font-p-small-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        --font-links-min-v: 16;
        --font-links-max-v: 18;
        --font-links-min: 16px;
        --font-links-max: 18px;
        --font-links: calc(var(--font-links-min) + (var(--font-links-max-v) - var(--font-links-min-v)) * ((100vw - var(--viewport-min)) / (var(--viewport-max-v) - var(--viewport-min-v))));
        /*Transitions*/
        --transition-side-menu: .4s ease-in-out;
        --transition-hover: .2s linear;
        --transition-slow: .5s ease-in-out;
    }

    /*#endregion */

    /*#region Classes
    --------------------------  */

    /* Flex */
    .flex {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

        .flex.column {
            -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
        }

        .flex > * {
            -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
        }

    .inner-container {
        margin: 0 auto;
        max-width: 100em;
        padding: 0 1rem;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .inner-narrow {
        max-width: 40rem;
        margin: 0 auto;
    }

    @media screen and (min-width: 450px) {
        .inner-container {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    @media screen and (min-width: 800px) {
        .inner-container {
            padding: 0 3.25em;
        }
    }

    @media screen and (min-width: 1200px) {
        .inner-container {
            padding: 0 6.25em;
        }
    }

    /* Page sections */
    .page-section {
        margin-top: 2em;
    }

    /*#endregion */

    /*#region Typography
    --------------------------  */

    h1, h2, h3, h4, h5 {
        font-family: var(--font-text);
        font-weight: 600;
        line-height: 1.2em;
        margin: 0 0 2rem 0;
        letter-spacing: -0.02em;
    }

    h1 {
        font-size: var(--font-h1);
    }

    h2 {
        font-size: var(--font-h2);
    }

    h3, h4, h5 {
        font-size: var(--font-h3);
    }

    p {
        font-size: var(--font-p);
        line-height: 1.5em;
        margin: 0 0 1em 0;
    }

    strong {
        font-weight: 400;
    }

    body {
        font-family: var(--font-text);
        font-weight: 300;
    }

    img {
        max-width: 100%;
    }

    /*#endregion */

    /*#region  Structure
    -------------------------------------  */

    html {
        font-size: 1em;
        scroll-behavior: smooth;
        min-width: 20em;
        height: 100%;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
        display: block;
    }

    picture {
        display: inline;
    }

    body {
        position: relative;
        text-align: left;
        font-size: 1em;
        background-color: var(--light-grey-20);
        color: var(--black);
        width: 100%;
        padding: 0 0 4.375em 0;
        margin: 0;
        min-width: 20em;
        height: 100%;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    main .inner-container {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    main *:last-child {
        margin-bottom: 0;
    }

    main a {
        color: var(--black);
        -moz-transition: color var(--transition-hover);
        -o-transition: color var(--transition-hover);
        -webkit-transition: color var(--transition-hover);
        transition: color var(--transition-hover);
    }

        main a:hover {
            color: var(--light-green);
        }

    /*#endregion */

    /*#region  Main
    -------------------------------------  */

    main.main {
        position: relative;
        z-index: 2;
        padding: 1em 1em 6em 1em;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        -moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }

    @media screen and (min-width: 600px) {
    }

    /*#endregion */

    /*#region  Loading animation
    -------------------------------------  */

    .loader {
        display: block;
        font-size: 10px;
        margin: 0 auto;
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;
        background: var(--light-grey);
        background: -moz-linear-gradient(left, var(--light-green) 10%, var(--light-grey) 42%);
        background: -webkit-linear-gradient(left, var(--light-green) 10%, var(--light-grey) 42%);
        background: -o-linear-gradient(left, var(--light-green) 10%, var(--light-grey) 42%);
        background: -ms-linear-gradient(left, var(--light-green) 10%, var(--light-grey) 42%);
        background: linear-gradient(to right, var(--light-green) 10%, var(--light-grey) 42%);
        -webkit-animation: load3 1.2s infinite linear;
        animation: load3 1.2s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

        .loader:before {
            width: 50%;
            height: 50%;
            background: var(--light-green);
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
        }

        .loader:after {
            background: var(--light-grey);
            width: 75%;
            height: 75%;
            border-radius: 50%;
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

    @-webkit-keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    /*#endregion */


</style>